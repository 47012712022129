<template>
  <div class="flight_box_one">
    <div class="row">
      <div class="col-lg-4">
        <div class="left_box">
          <div class="flight_icon">
            <img :src="`${imageDomain}/assets/img/img10.png`" alt="img10" title="" :class="{ 'return-air': type === 'return' }" width="64" height="64"/>
          </div>
          <h3 class="text-center" v-if="type === 'depature'">{{ $t("flight-only.round-trip-departure") }}</h3>
          <h3 class="text-center" v-if="type === 'return'">{{ $t("flight-only.round-trip-return") }}</h3>
          <div class="d-flex">
            <div class="img_box m-auto">
              <img v-if="!data || !data.airlineNotConfirmed" :src="markAirline" :alt="airlineName" title="" width="85" height="85"/>
            </div>
          </div>
          <div class="clearfix"></div>
          <h4 class="text-center mt-2" >....{{ airlineName }}</h4>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="right_box">
          <div class="right_box_one" v-if="type === 'depature'">
            <div class="d-flex">
              <h3>
                {{ $t("search-result.from") }}{{lang !== 'he' ? " " : ""}}{{ fromDestName }}
              </h3>
              <span class="icon_derection"><i class="fas fa-long-arrow-alt-left"></i></span>
              <h3>
                <!-- {{ $t("search-result.to") }}{{lang !== 'he' ? " " : ""}}{{ toDestName }}{{` (${toRoute})` }} -->
                {{ $t("search-result.to") }}{{lang !== 'he' ? " " : ""}}{{ toDestName }}
              </h3>
            </div>
          </div>
          <div class="right_box_one" v-else-if="type === 'return'">
            <div class="d-flex">
              <h3>
                {{ $t("search-result.from") }}{{lang !== 'he' ? " " : ""}}{{ fromDestName }}
              </h3>
              <span class="icon_derection"><i class="fas fa-long-arrow-alt-left"></i></span>
              <h3>
                {{ $t("search-result.to") }}{{lang !== 'he' ? " " : ""}}{{ toDestName }}
              </h3>
            </div>
          </div>
          <div class="right_box_two">
            <h5>
              {{ $t("flight-only.departure-message", { time: departHour, date: departDate, airport: fromAirportName, terminal: fromTerminal}) }}
            </h5>
            <h5 v-if="isConnectFlight" class="step-link" @click="showFlightDetail">
              <i class="fas fa-info-circle" data-placement="left"></i>
              {{ stopoverMessage }}
            </h5>
            <h5 v-if="isConnectFlight">
              {{ $t("flight-only.landing-message-via", { viaRouteName, viaDepTime, time: arriveHour, date: arriveDate, airport: arriveAirportName, terminal: arriveTerminal}) }}
            </h5>
            <h5 v-else>
              {{ $t("flight-only.landing-message", { time: arriveHour, date: arriveDate, airport: arriveAirportName, terminal: arriveTerminal }) }}
            </h5>
          </div>
          <button class="btn-flight">
            <span v-if="data && data.Authorization_msg.includes('לא אושרו')">{{ $t("flight-only.flight-no") }}: N/A</span>
            <span v-else>{{ $t("flight-only.flight-no") }}: {{ airlineNumber }}</span>
          </button>
        </div>
      </div>
      <div class="col-lg-12 baggage">
        <h3>{{ $t("flight-only.baggage-details-title") }}</h3>
        <div class="flight_box_baggage_body">
          <div class="d-flex justify-content-between">
            <div class="flight_box_one_div w-100">
              <span class="img_icon"><img :src="`${imageDomain}/assets/img/img11.png`" alt="img11" title="" width="21" height="18"/></span>
              <h5>
                <!-- {{ $t("flight-only.luggage-plane-desc") }} -->
                <!-- <span>{{ $t("flight-only.luggage-plane-desc") }}
                  {{ $t("flight-only.no-extra-charge") }}, {{ $t("flight-only.max") }}:
                  {{ data.FL_Max_Weight }}Kg
                </span> -->
                <span>{{$t("flight-only.luggage-description", {weight: weightLuggage})}}</span>
              </h5>
            </div>
            <div class="flight_box_one_div w-100" v-if="weightBag">
              <span class="img_icon"><img :src="`${imageDomain}/assets/img/img12.png`" alt="img12" title="" width="16" height="26"/></span>
              <h5>
                <!-- {{ $t("flight-only.including-small-desc") }} -->
                <!-- <span>
                  {{ $t("flight-only.to-board-plane") }} ,
                  {{ $t("flight-only.no-extra-charge") }}, {{ $t("flight-only.max") }}:
                  {{ data.FL_Max_Weight_Trolley }}Kg
                </span> -->
                <span>{{$t("flight-only.bag-description", {weight: weightBag})}}</span>
              </h5>
            </div>
            <div class="flight_box_one_div w-100" v-else>
              <span class="img_icon"><img :src="`${imageDomain}/assets/img/img14.png`" alt="img14" title="" width="16" height="26"/></span>
              <h5>
                <span>{{$t("flight-only.no-bag")}}</span>
              </h5>
            </div>
          </div>
        </div>
        <!-- <div class="flight_tag_box">
          <h3>
            <span class="icon_check"><i class="fas fa-check-circle"></i></span>{{ authorMessage }}
          </h3>
        </div> -->
      </div>
    </div>

    <FlightDetailsPopup
      :data="flightDetails"
      :showFlag="showDetailFlag"
      :closePopup="closeDetailPopup"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    airInfo: {
      type: Object,
      default: null,
    },
  },
  components: {
    FlightDetailsPopup: () => import('@/components/atoms/FlightDetailsPopup'),
  },
  data() {
    return {
      markAirline: `${this.imageDomain}/assets/img/flying.png`,
      showDetailFlag: false,
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
    }),
    airlineName() {
      return this.data?.arl_Name ?? '';
    },
    fromRoute() {
      return this.data?.FL_From_Route ?? '';
    },
    fromDestName() {
      return this.getCityName(this.fromRoute);
    },
    departHour() {
      return this.getTimeFormatDirectly(this.data?.FL_Dep_Hour ?? '');
    },
    departDate() {
      return this.data ? this.getDateFormat(this.data?.FL_Date) : '';
    },
    departWeek() {
      return this.data ? this.getWeek(this.data?.FL_Date) : '';
    },
    fromAirportName() {
      return this.getAirportName(this.data?.FL_From_Air_Port || '');
    },
    toRoute() {
      return this.data?.FL_To_Route ?? '';
    },
    toDestName() {
      return this.getCityName(this.toRoute);
    },
    isConnectFlight() {
      return !!this.data?.FL_Via_Air_Port;
    },
    viaRouteName() {
      return this.getCityName(this.data?.FL_Flt_Via || '');
    },
    viaAirPortName() {
      return this.getAirportName(this.data?.FL_Via_Air_Port || '');
    },
    viaTerminal() {
      return this.data?.FL_Via_Terminal ?? '';
    },
    viaLandTime() {
      return this.data?.FL_ViaDep_Hour || false ? this.getTimeFormat(this.data.FL_Via_Hour) : '';
    },
    viaDepTime() {
      return this.data?.FL_ViaDep_Hour || false ? this.getTimeFormat(this.data.FL_ViaDep_Hour) : '';
    },
    viaStopTimeHours() {
      if (!this.data) return 0;
      return Number(this.data.stopOverTime.split(':')[0]);
    },
    viaStopTimeMinutes() {
      if (!this.data) return 0;
      return Number(this.data.stopOverTime.split(':')[1]);
    },
    arriveHour() {
      return this.getTimeFormatDirectly(this.data?.FL_Arrv_Hour ?? '');
    },
    arriveDate() {
      return this.data ? this.getDateFormat(this.data.landingDate) : '';
    },
    arriveWeek() {
      return this.data ? this.getWeek(this.data.landingDate) : '';
    },
    arriveAirportName() {
      return this.getAirportName(this.data?.FL_To_Air_Port || '');
    },
    airlineNumber() {
      return this.data ? `${this.data.FL_AIRLINE} - ${this.data.FL_Flt_Number}` : '';
    },
    authorMessage() {
      return this.data ? this.data.Authorization_msg : '';
    },
    fromTerminal() {
      return this.stringTerminal(this.data?.FL_From_Terminal || 'N/A');
    },
    arriveTerminal() {
      return this.stringTerminal(this.data?.FL_To_Terminal || 'N/A');
    },
    weightLuggage() {
      return this.data?.FL_Max_Weight || 20;
    },
    weightBag() {
      return +this.data?.FL_Max_Weight_Trolley || 0;
    },
    stopoverMessage() {
      if (this.viaStopTimeHours > 0 && this.viaStopTimeMinutes > 0) {
        return this.$t('flight-only.stopover-message', {
          city: this.viaAirPortName,
          hours: this.viaStopTimeHours,
          minutes: this.viaStopTimeMinutes,
        });
      } else if (this.viaStopTimeHours > 0) {
        return this.$t('flight-only.stopover-message-hours-only', {
          city: this.viaAirPortName,
          hours: this.viaStopTimeHours,
        });
      } else if (this.viaStopTimeMinutes > 0) {
        return this.$t('flight-only.stopover-message-minutes-only', {
          city: this.viaAirPortName,
          minutes: this.viaStopTimeMinutes,
        });
      }
      return '';
    },
    flightDetails() {
      return this.data;
    },
  },
  watch: {
    airInfo() {
      if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
        this.markAirline = this.airInfo.desc2 || `${this.imageDomain}/assets/img/flying.png`;
      }
    },
  },
  beforeMount() {
  },
  created() {
    if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
      this.markAirline = this.airInfo.desc2 || `${this.imageDomain}/assets/img/flying.png`;
    }
  },
  methods: {
    getDateFormat(str) {
      return dayjs(str).format('DD.MM.YY');
    },
    getTimeFormat(str) {
      return dayjs(str).format('HH:mm');
    },
    getTimeFormatDirectly(str) {
      return dayjs(`${new Date().toISOString().split('T')[0]}T${str}`).format('HH:mm');
    },
    getDateTimeFormat(str) {
      return dayjs(str).format('DD.MM.YY hh:mm A');
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    getCityName(code) {
      return this.data?.translations?.flightDestinationName?.[code]?.[this.lang] ?? code;
    },
    getAirportName(code) {
      return this.data?.translations?.airportName[code]?.[this.lang] ?? code;
    },
    stringTerminal(pTerminal) {
      return pTerminal !== 'N/A' ? `(${this.$t('flight-only.terminal')} ${pTerminal})` : '';
    },
    showFlightDetail() {
      this.showDetailFlag = true;
    },
    closeDetailPopup() {
      this.showDetailFlag = false;
    },
  },
};
</script>
<style scoped>
img.return-air {
  transform: rotate(180deg);
}
.flight_box_baggage_body .flight_box_one_div .img_icon img {
  width: 50%;
  height: auto;
}
.step-link {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.step-link i {
  margin: 5px 0px 5px 5px;
}
@media (max-width: 768px) {
  .flight_only_area .flight_only_body .flight_box_one .left_box .flight_icon {
    top: 0;
  }
  .flight_only_area .flight_only_body .flight_box_one .right_box .right_box_one .icon_derection {
    margin-top: -0.3rem;
  }
}
</style>
